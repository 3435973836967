import { Fade } from "react-reveal";
import "./style.css";
import warn from "./img/warn.svg";
import lod from "./img/load.svg";
import bit from "./img/bit.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import mobit from "./img/mobit.svg";
const nums = [0, 110];
export default function Calculator() {
  const [summ, setSumm] = useState();
  let R = 6.25;
  let D = 70343519904867;
  let t = 86400;
  const [value, setValue] = useState(55);
  const [dollar, setDollar] = useState();
  function getBTC() {
    fetch("https://blockchain.info/ticker")
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setDollar(data.USD.buy);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    let N = (t * R * value) / ((D / 1000000000000) * Math.pow(2, 32));
    setSumm(N);
  }, [value]);
  useEffect(() => {
    getBTC();
  }, []);
  return (
    <Fade top duration={700}>
      <div className="calc container">
        <div className="leftCalc">
          <h2 className="terH">{value} Terahash</h2>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Enter the number of Terahashes"
          />
          <div className="sale">for {value * 99}$</div>
          <h3>Economy</h3>
          <div className="economy">
            <p style={{ color: "#4C91E5" }}>Service</p>
            <div>
              <p>
                <span>$ </span>3.000
              </p>
              <p>
                <span>₿ </span>0.0684
              </p>
            </div>
          </div>
          <div className="economy">
            <p style={{ color: "#6ED375" }}>Hosting</p>
            <div>
              <p>
                <span>$ </span>3.000
              </p>
              <p>
                <span>₿ </span>0.0684
              </p>
            </div>
          </div>
          <div className="economy">
            <p style={{ color: "#EFA058" }}>Electricity</p>
            <div>
              <p>
                <span>$ </span>3.000
              </p>
              <p>
                <span>₿ </span>0.0684
              </p>
            </div>
          </div>
        </div>
        <div className="rightCalc">
          <div className="warn">
            <h2>Your profit</h2>
            <div>
              <img src={warn} alt="" />
              <p>
                <b>Attention!</b> These calculations are approximate, depend on
                the current state of the market, as well as the cost of bitcoin,
                the difficulty of mining, and the size of the reward. This
                number can change in either direction.
              </p>
            </div>
          </div>
          <input
            type="range"
            name=""
            id="kolom"
            step={5}
            min={0}
            max={110}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <div className="numsic">
            {nums.map((em) => {
              return (
                <div>
                  <div className="linik"></div>
                  <p>{em}</p>
                </div>
              );
            })}
          </div>
          <div className="gridDay">
            <div className="gridEm">
              <h4 style={{ color: "#4C91E5" }}>Day</h4>
              <p>
                <span>$ </span> {summ && dollar && (summ * dollar).toFixed(2)}
              </p>
              <p>
                <span>₿ </span>
                {summ && summ.toFixed(8)}
              </p>
              <span>Enter the amount in the income field</span>
            </div>
            <div className="gridEm">
              <h4 style={{ color: "#6ED375" }}>Month</h4>
              <p>
                <span>$ </span>
                {summ && dollar && (summ * dollar * 30).toFixed(2)}
              </p>
              <p>
                <span>₿ </span> {summ && (summ * 30).toFixed(8)}
              </p>
              <span>Enter the amount in the income field</span>
            </div>
            <div className="gridEm">
              <h4 style={{ color: "#EFA058" }}>Year</h4>
              <p>
                <span>$ </span>
                {summ && dollar && (summ * dollar * 365).toFixed(2)}
              </p>
              <p>
                <span>₿ </span> {summ && (summ * 365).toFixed(8)}
              </p>
              <span>Enter the amount in the income field</span>
            </div>
            <div className="gridEm">
              <h4 style={{ color: "#6FD1C5" }}>2 Years</h4>
              <p>
                <span>$ </span>
                {summ && dollar && (summ * dollar * 365 * 2).toFixed(2)}
              </p>
              <p>
                <span>₿ </span> {summ && (summ * 365 * 2).toFixed(8)}
              </p>
              <span>Enter the amount in the income field</span>
            </div>
            <div className="gridEm">
              <h4 style={{ color: "#8774D4" }}>5 Years</h4>
              <p>
                <span>$ </span>
                {summ && dollar && (summ * dollar * 365 * 5).toFixed(2)}
              </p>
              <p>
                <span>₿ </span> {summ && (summ * 365 * 5).toFixed(8)}
              </p>
              <span>Enter the amount in the income field</span>
            </div>
            <div className="gridEm">
              <h4 style={{ color: "#F18383" }}>10 Years</h4>
              <p>
                <span>$ </span>
                {summ && dollar && (summ * dollar * 365 * 10).toFixed(2)}
              </p>
              <p>
                <span>₿ </span> {summ && (summ * 365 * 10).toFixed(8)}
              </p>
              <span>Enter the amount in the income field</span>
            </div>
          </div>
          <img
            src={document.body.clientWidth > 640 ? bit : mobit}
            className="bit"
            alt=""
          />
        </div>
      </div>
    </Fade>
  );
}
