import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import logo from "./img/logo.svg";
import right from "./img/right.png";
import { Fade, Flip, Slide, Zoom } from "react-reveal";
import { routes } from "../../routes";
import { useEffect, useState } from "react";
import { linkApi } from "../../consts";
export default function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    if (localStorage.email) {
      setEmail(localStorage.email);
    }
  }, []);
  function auth() {
    let obj = {};
    obj.email = email;
    obj.password = password;
    /*   fetch(linkApi + "auth/login", {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          return;
        }
      })
      .then((data) => {
        if (data && data.token) {
          localStorage.setItem("token", data.token);
          Navigate(routes.dashboard);
        }
      }); */
    Navigate(routes.dashboard);
  }
  const Navigate = useNavigate();
  return (
    <div className="auth">
      <Fade left duration={700}>
        <div className="leftAuth">
          <img src={logo} alt="" className="logo" />
          <div className="navAuth">
            <p>Login</p>

            <div></div>
            <Link to={routes.register}>
              <p className="disabled">SignUp</p>
            </Link>
          </div>
          <p className="feeling">
            Fill in the fields to log in to your account.
          </p>
          <div className="inputAuth">
            <p>
              Email<div className="zvezd">*</div>
            </p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
          </div>
          <div className="inputAuth">
            <p>
              Password<div className="zvezd">*</div>
            </p>
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <div className="login" onClick={() => auth()}>
            Login
          </div>
        </div>
      </Fade>
      <Fade right duration={700}>
        <div className="rightAuth hero">
          <div class="bg"></div>
          <div class="bg bg2"></div>
          <div class="bg bg3"></div>
          <img src={right} alt="" />

          <h4>
            Come for new <br />
            achievements in cryptocurrency
          </h4>
          <p>
            We are glad to see you as a part
            {document.body.clientWidth > 640 && <br />} of our team
          </p>
        </div>
      </Fade>
    </div>
  );
}
