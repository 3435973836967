import "./style.css";
import loop from "./img/loop.svg";
import set from "./img/set.svg";
import col from "./img/col.svg";
import ava from "./img/ava.svg";
import { Fade } from "react-reveal";
import logout from "./img/logout.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useContext } from "react";
import { UserData } from "../../Context/UserData";
import logo from "./img/log.svg";
export default function Header({ navOpen, isNavOpen }) {
  const Navigate = useNavigate();
  const name = JSON.parse(localStorage.user || null);
  return (
    <Fade top duration={700}>
      <div className="header">
        {document.body.clientWidth < 640 && (
          <img src={logo} className="hdlogo" />
        )}
        {document.body.clientWidth < 640 && (
          <div className="mobileButton" onClick={() => isNavOpen(true)}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <div className="search">
          <img src={loop} alt="" />
          <input type="text" placeholder="Search..." />
          <div className="searchButton">Search</div>
        </div>
        <img src={col} alt="" />
        <div className="headerProfile">
          <img src={ava} alt="" className="ava" />
          <p>{name && name.fullname}</p>
          <img src={set} alt="" className="settings" />
        </div>
        <div
          className="logout"
          onClick={() => {
            localStorage.clear();
            Navigate(routes.auth);
          }}
        >
          <img src={logout} alt="" />
          <p>Log out</p>
        </div>
      </div>
    </Fade>
  );
}
