import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import logo from "./img/logo.svg";
import right from "./img/right.png";
import { Fade, Flip, Slide, Zoom } from "react-reveal";
import { routes } from "../../routes";
import { useState } from "react";
import { linkApi } from "../../consts";
export default function Registration() {
  const Navigate = useNavigate();
  const [form, setForm] = useState({
    fullname: "",
    password: "",
    email: "",
    numberphone: "",
  });
  console.log(form);
  const [error, setError] = useState(false);
  console.log(form.fullname.length);
  const registration = (e) => {
    e.preventDefault();
    fetch(linkApi + "auth/", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status == 201) {
        localStorage.setItem("email", form.email);
        Navigate(routes.auth);
      }
    });
  };

  const update = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="auth register">
      <Fade top duration={700}>
        <div className="leftAuth">
          <img src={logo} alt="" className="logo" />
          <div className="navAuth">
            <Link to={routes.auth}>
              <p className="disabled">Login</p>
            </Link>
            <div></div>
            <p>SignUp</p>
          </div>
          <p className="feeling">
            Increase your income with the help of an innovative mining system!
          </p>
          <div className="flexInputs">
            <div className="inputAuth">
              <p>
                Full name<div className="zvezd">*</div>
              </p>
              <input
                type="text"
                onChange={update}
                name="fullname"
                placeholder="Enter your name"
                className={form.fullname.length == 0 && error ? "error" : ""}
              />
            </div>
            <div className="inputAuth">
              <p>
                Number phone<div className="zvezd">*</div>
              </p>
              <input
                type="tel"
                onChange={update}
                name="numberphone"
                placeholder="Enter your phone"
                className={form.numberphone.length < 7 && error ? "error" : ""}
              />
            </div>
          </div>
          <div className="inputAuth">
            <p>
              Email<div className="zvezd">*</div>
            </p>
            <input
              type="email"
              name="email"
              onChange={update}
              placeholder="Email"
              className={
                (form.email.length == 0 || !form.email.includes("@")) && error
                  ? "error"
                  : ""
              }
            />
          </div>
          <div className="inputAuth">
            <p>
              Password<div className="zvezd">*</div>{" "}
              <span
                style={
                  form.password.length < 8 && error
                    ? { color: "rgb(255, 52, 52)" }
                    : { display: "none" }
                }
              >
                The password must be more than 7 characters long
              </span>
            </p>
            <input
              type="password"
              name="password"
              onChange={update}
              className={form.password.length < 8 && error ? "error" : ""}
              placeholder="Password"
            />
          </div>
          <div
            className="login"
            onClick={(e) =>
              form.fullname.length > 0 &&
              form.email.length > 0 &&
              form.email.includes("@") &&
              form.numberphone.length >= 7 &&
              form.password.length >= 8
                ? registration(e)
                : setError(true)
            }
          >
            Sign up
          </div>
          <div className="agreement">
            <input type="checkbox" defaultChecked name="" id="agree" />
            <label htmlFor="agree">
              I agree to the <a href="">Terms&Conditions</a>
            </label>
          </div>
        </div>
      </Fade>
      <Fade bottom duration={700}>
        <div className="rightAuth hero">
          <div className="bg"></div>
          <div className="bg bg2"></div>
          <div className="bg bg3"></div>
          <img src={right} alt="" />

          <h4>
            Increase your income by <br /> conquering Cryptocurrency
          </h4>
          <p>
            our team has developed a new system that makes it easy <br /> and
            simple to work with cryptocurrency online
          </p>
        </div>
      </Fade>
    </div>
  );
}
