import { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import "./style.css";
import stat from "./img/stat.svg";
import Typewriter from "typewriter-effect";
import star from "./img/star.svg";
import { Fade } from "react-reveal";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";
import { linkApi } from "../../consts";
import { UserData } from "../../Context/UserData";
export default function Dashboard() {
  const [userData, setUserData] = useContext(UserData);
  const Navigate = useNavigate();
  console.log(userData.fullname);
  function getData() {
    fetch(linkApi + "user/", {
      type: "POST",
      contentType: "application/json",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => {
        if (response.status != 200) {
          localStorage.clear();
          Navigate(routes.auth);
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data) {
          setUserData(data);
          localStorage.setItem("user", JSON.stringify(data));
        }
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <Fade top duration={700}>
      <div className="container">
        <div className="dashboard">
          <div className="leftDash">
            <h2>
              {/* <Typewriter
              options={{
                strings: ["Hi, Kevin! Welcome Back."],
                autoStart: true,
              }}
              eraseDelay={10000}
            /> */}
              {userData && userData.fullname && (
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `Hi, ${
                          userData && userData.fullname && userData.fullname
                        } <br> Welcome Back.`
                      )
                      .pauseFor(500)
                      .start()
                      .callFunction(() => {
                        let cursor =
                          document.getElementsByClassName(
                            "Typewriter__cursor"
                          )[0];
                        setTimeout(() => {
                          cursor.style.display = "none";
                        }, 500);
                      });
                  }}
                  options={{
                    changeDelay: 10,
                  }}
                />
              )}
            </h2>
            <p className="redText">
              Your next payment of <b>$1900,89</b> is scheduled for
              <span> Feb 25, 2024</span>
            </p>
            <div className="lighting">
              Check the profit growth of your cryptocurrency <br /> on the
              calculator since your last visit ⚡
            </div>
            <div
              className="buttonCalc"
              onClick={() => Navigate(routes.calculator)}
            >
              Go to Calculator
            </div>
            <img src={stat} alt="" className="stat" />
            <div className="referal">
              <img src={star} alt="" />
              <p>
                Get an extra $50 for an invited friend - Code <b>Y54g45Dg</b>
              </p>
            </div>
          </div>
          <div className="rightDash">
            {" "}
            <model-viewer
              src="ant_miner_s19_pro.glb"
              ar-modes="webxr scene-viewer quick-look"
              camera-controls
              tone-mapping="commerce"
              poster="poster.webp"
              shadow-intensity="2"
              auto-rotate
              interaction-prompt="none"
              auto-rotate-delay="0"
              camera-orbit="16.81deg 86.95deg 15.98m"
              field-of-view="26.26deg"
              style={{ width: "100%", height: "100%" }}
            >
              <div class="progress-bar hide" slot="progress-bar">
                <div class="update-bar"></div>
              </div>
            </model-viewer>{" "}
          </div>
        </div>
      </div>
    </Fade>
  );
}
