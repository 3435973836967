export const routes = {
  main: "/",
  auth: "/auth",
  register: "/registration",
  dashboard: "/dashboard",
  calculator: "/calculator",
  profile: "/profile",
  payment: "/payment",
  faq: "/faq",
};
