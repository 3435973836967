import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { routes } from "./routes";

import { Lang } from "./Lang";
import "./fonts/stylesheet.css";
import { useEffect, useState } from "react";
import Auth from "./Pages/Auth";
import Dashboard from "./Pages/Dashboard";
import Registration from "./Pages/Registration";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Profile from "./Pages/Profile";
import Calculator from "./Pages/Calculator";
import { UserData } from "./Context/UserData";
import FAQ from "./Pages/FAQ";

function App() {
  const [userData, setUserData] = useState({});
  const [lang, setLang] = useState("ru");
  const location = useLocation();
  const [navOpen, isNavOpen] = useState(
    document.body.clientWidth > 640 ? true : false
  );

  return (
    <div className="App">
      <UserData.Provider value={[userData, setUserData]}>
        <Lang.Provider value={[lang, setLang]}>
          {location.pathname != routes.auth &&
            location.pathname != routes.register &&
            location.pathname != routes.main && (
              <Navbar navOpen={navOpen} isNavOpen={isNavOpen}></Navbar>
            )}
          {location.pathname != routes.auth &&
            location.pathname != routes.register &&
            location.pathname != routes.main && (
              <Header navOpen={navOpen} isNavOpen={isNavOpen}></Header>
            )}
          <Routes>
            <Route path={routes.auth} element={<Auth></Auth>}></Route>
            <Route path={routes.main} element={<Auth></Auth>}></Route>
            <Route
              path={routes.register}
              element={<Registration></Registration>}
            ></Route>
            <Route path={routes.profile} element={<Profile></Profile>}></Route>
            <Route path={routes.faq} element={<FAQ></FAQ>}></Route>
            <Route
              path={routes.dashboard}
              element={<Dashboard></Dashboard>}
            ></Route>
            <Route
              path={routes.calculator}
              element={<Calculator></Calculator>}
            ></Route>
          </Routes>
        </Lang.Provider>
      </UserData.Provider>
    </div>
  );
}

export default App;
