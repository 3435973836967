import { Fade } from "react-reveal";
import "./style.css";
import profile from "./img/profile.png";
import pass from "./img/pass.svg";
import save from "./img/save.svg";
import { useContext, useState } from "react";
import { UserData } from "../../Context/UserData";
export default function Profile() {
  const [change, setChange] = useState(JSON.parse(localStorage.user) || null);
  const update = (e) => {
    setChange({
      ...change,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Fade top duration={700}>
      <div className="container">
        <div className="profile">
          <div className="leftProfile">
            <h2>Your profile</h2>
            <p>It contains up-to-date information about you.</p>
            <div className="inputAuth">
              <p>Full name</p>
              <input
                type="text"
                name="fullname"
                placeholder=""
                value={change.fullname}
                onChange={update}
              />
            </div>
            <div className="inputAuth">
              <p>Transfer to the pool with a hashrate</p>
              <input type="text" placeholder="" />
            </div>
            <div className="flexInputs">
              <div className="inputAuth">
                <p>Email</p>
                <input
                  type="email"
                  name="email"
                  value={change.email}
                  onChange={update}
                  placeholder=""
                />
              </div>
              <div className="inputAuth">
                <p>Phone</p>
                <input
                  type="tel"
                  placeholder=""
                  name="numberphone"
                  value={change.numberphone}
                  onChange={update}
                />
              </div>
            </div>

            <div className="inputAuth">
              <p>Bitcoin wallet</p>
              <input type="text" placeholder="" />
            </div>
            <div className="flexInputs withButton">
              <div className="inputAuth">
                <p>Last password</p>
                <input type="password" placeholder="" />
              </div>
              <div className="inputAuth">
                <p>New password</p>
                <input type="password" placeholder="" />
              </div>
              <div className="savePassword">
                <p>Change</p>
                <img src={pass} alt="" />
              </div>
            </div>
            <div className="saveButton">
              <p> Save changes </p>
              <img src={save} alt="" />
            </div>
          </div>
          <div className="rightrofile">
            <h2>Photo</h2>
            <p>Change your photo</p>
            <img src={profile} alt="" />
          </div>
        </div>
      </div>
    </Fade>
  );
}
