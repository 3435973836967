import { Link, useLocation, useNavigate } from "react-router-dom";
import img1 from "./img/1.svg";
import img2 from "./img/2.svg";
import img3 from "./img/3.svg";
import img4 from "./img/4.svg";
import img5 from "./img/5.svg";
import img11 from "./img/a1.svg";
import img22 from "./img/a2.svg";
import img33 from "./img/a3.svg";
import img44 from "./img/a4.svg";
import img55 from "./img/a5.svg";
import logo from "./img/logo.svg";
import img6 from "./img/6.svg";
import "./style.css";
import { routes } from "../../routes";
import { Fade } from "react-reveal";
import { useEffect, useState } from "react";
export default function Navbar({ navOpen, isNavOpen }) {
  const location = useLocation();
  const Navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div
      className="navBar"
      style={
        navOpen
          ? { transform: "translateX(0)" }
          : { transform: "translateX(100vw)" }
      }
    >
      <img src={logo} alt="" />
      <ul>
        <li onClick={() => document.body.clientWidth < 640 && isNavOpen(false)}>
          <Link to={routes.dashboard}>
            <img
              src={img1}
              className={location.pathname == routes.dashboard ? "kek" : ""}
              alt=""
            />
            <p className={location.pathname == routes.dashboard ? "str" : ""}>
              Dashboard
            </p>
          </Link>
        </li>
        <li onClick={() => document.body.clientWidth < 640 && isNavOpen(false)}>
          <Link to={routes.calculator}>
            <img
              src={img2}
              className={location.pathname == routes.calculator ? "kek" : ""}
              alt=""
            />
            <p className={location.pathname == routes.calculator ? "str" : ""}>
              Calculator
            </p>
          </Link>
        </li>
        <li onClick={() => document.body.clientWidth < 640 && isNavOpen(false)}>
          <Link to={routes.profile}>
            <img
              src={img3}
              className={location.pathname == routes.profile ? "kek" : ""}
              alt=""
            />
            <p className={location.pathname == routes.profile ? "str" : ""}>
              Profile
            </p>
          </Link>
        </li>
        <li onClick={() => document.body.clientWidth < 640 && isNavOpen(false)}>
          <Link to={routes.payment}>
            <img
              src={img4}
              alt=""
              className={location.pathname == routes.payment ? "kek" : ""}
            />
            <p className={location.pathname == routes.payment ? "str" : ""}>
              Payment
            </p>
          </Link>
        </li>
        <li onClick={() => document.body.clientWidth < 640 && isNavOpen(false)}>
          <Link to={routes.faq}>
            <img
              src={img5}
              className={location.pathname == routes.faq ? "kek" : ""}
              alt=""
            />
            <p className={location.pathname == routes.faq ? "str" : ""}>FAQ</p>
          </Link>
        </li>
        {document.body.clientWidth < 640 && (
          <li
            onClick={() => {
              Navigate(routes.auth);
              localStorage.clear();
              isNavOpen(false);
            }}
          >
            <Link>
              <img src={img6} alt="" />
              <p>Выйти</p>
            </Link>
          </li>
        )}
        {document.body.clientWidth < 640 && (
          <li
            onClick={() => {
              isNavOpen(false);
            }}
          >
            <Link>
              <div className="closeM">Закрыть</div>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
